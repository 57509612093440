import logo from "./logo.svg";

import "./App.css";
import "./data/offers";
import offers from "./data/offers";
import { useRef } from "react";
import Certificate from "./Components/Certificate";
import { FireIcon } from "@heroicons/react/24/solid";
import { Disclosure } from "@headlessui/react";

function App() {
  const cardsRef = useRef([]);

  const SecondBtn = ({ title, href }) => {
    return (
      <a
        className=" w-full my-2 rounded-md bg-gray-150 px-3.5 py-2.5 text-sm  bg-gray-100 text-gray-800 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  hover:text-white"
        target="_blank"
        href={href}
      >
        {title}
      </a>
    );
  };

  const showDopInfo = (item) => {
    cardsRef.current[item.id]
      .getElementsByClassName("card_dop_info")[0]
      .classList.remove("hidden");

    cardsRef.current[item.id]
      .getElementsByClassName("card_main_info")[0]
      .classList.add("hidden");
  };

  const hideDopInfo = (item) => {
    cardsRef.current[item.id]
      .getElementsByClassName("card_dop_info")[0]
      .classList.add("hidden");

    cardsRef.current[item.id]
      .getElementsByClassName("card_main_info")[0]
      .classList.remove("hidden");
  };

  const sendConversion = ({ item }) => {
    if (window.gtag) {
      window.gtag("event", "purchase", {
        items: [
          {
            item_id: item.id,
            item_name: item.name,
          },
        ],
      });
    }
  };

  const MainCardView = ({ item }) => {
    return (
      <div className="z-0 flex h-max  bg-white flex-col gap-3 items-center justify-center max-w-sm  border border-blue-200 rounded-lg p-2  shadow-xl shadow-slate-400">
        <div className="h-20 w-full relative">
          <img
            className=" absolute w-full h-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 object-contain"
            src={process.env.PUBLIC_URL + "/logos/" + item.image}
          />
        </div>
        <div className=" flex flex-col gap-3 w-full">
          <p className=" flex flex-row align-middle text-center justify-center">
            {item.id <= 3 && (
              <div className=" flex flex-row bg-white mr-3  ">
                <FireIcon className=" top-0 left-2  w-5 h-5 text-orange-700 " />
                <p className=" text-orange-700 font-extrabold">ТОП {item.id}</p>
              </div>
            )}
            <span className=" font-bold text-gray-700">{item.name}</span>
          </p>
          <div className=" w-full border border-gray-200 p-2 text-indigo-900  font-extrabold">
            <p className=" w-full flex flex-col md:flex-row justify-between text-balance">
              <span className=" font-light">Сума до:</span>
              <span>{item.amount_to} грн</span>
            </p>
            <p className=" w-full flex-col md:flex-row flex justify-between text-balance mt-1 md:mt-4">
              <span className=" font-light">Строк до:</span>
              <span>{item.days_to} днів</span>
            </p>
          </div>
        </div>
        <a
          className=" w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white focus-visible:text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          href={item.link}
          target="_blank"
          onClick={(_) => sendConversion({ item: item })}
        >
          ОФОРМИТИ
        </a>
        <Disclosure>
          <Disclosure.Button className="py-1 md:py-2 text-gray-700">
            Інформація
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500 flex flex-col">
            <div className="card_dop_info hidden   max-w-max">
              <p className=" border border-gray-200 p-1 w-full p-3 ">
                {item.dop_info}
              </p>
              <span
                onClick={(_) => hideDopInfo(item)}
                className=" font-light text-blue-500"
              >
                Закрити
              </span>
            </div>
            <div className="card_main_info w-full flex">
              <span
                onClick={(_) => showDopInfo(item)}
                className="w-full rounded-md my-2 bg-gray-150 px-3.5 py-2.5 text-sm  bg-gray-100 text-gray-800 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  hover:text-white"
              >
                Відомості про компанію
              </span>
            </div>
            <SecondBtn
              title={"Істотні характеристики послуги"}
              href={item.warning_link}
            />
            <SecondBtn
              title={"Попередження про можливі наслідки"}
              href={item.service_characteristics_link}
            />
          </Disclosure.Panel>
        </Disclosure>
      </div>
    );
  };

  return (
    <div className="App relative   bg-gradient-to-r  from-indigo-100 from-10% via-sky-100 via-30% to-emerald-100 to-90% h-full">
      <header className=""></header>

      <div className="container-full z-2">
        <div className="flex flex-col justify-center align-middle items-center">
          <div className="my-3 flex justify-center align-middle items-center flex-col ">
            <img className="max-w-60 self-center" src={logo} />
            <div className=" w-full container">
              <p className="  text-lg p-3 bg-white mt-1 w-full text-gray-700">
                Декілька заявок збільшують шанси
              </p>
            </div>
          </div>

          <div className=" px-3 md:px-3 w-full mt-1 md:mt-5  md:max-w-screen-xl group grid grid-cols-2 lg:grid-cols-4 gap-3 md:gap-5 z-10">
            {offers.map((el, i) => {
              return (
                <div
                  ref={(el) => (cardsRef.current[el.id] = el)}
                  key={el.id}
                  id={el.id}
                >
                  <MainCardView item={el} />
                </div>
              );
            })}
          </div>

          <div className="my-5 container">
            <div className=" bg-slate-50/50 p-4 my-4 ">
              <Certificate />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
