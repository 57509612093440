import React from "react";

const Certificate = () => {
  const certificate = [
    {
      id: 1,
      title: "Вимоги до позичальників",
      text: "Громадянство України; Вік від 18 років; Будь-яка кредитна історія.",
    },
    {
      id: 2,
      title: "Мінімальний термін погашення",
      text: "Мінімальний термін погашення боргу (minimum and maximum period for repayment) становить 62 дні.",
    },
    {
      id: 3,
      title: "Максимальний термін погашення",
      text: "Максимальний термін погашення боргу 365 днів.",
    },
    {
      id: 4,
      title: "Максимальна річна відсоткова ставка",
      text: "Максимальна річна відсоткова ставка, що включає позичковий відсоток, а також всі інші комісії і витрати за рік (maximum Annual Percentage Rate (APR), including interest rate plus fees and other costs for a year) становить 31%, а мінімальна 3%. Річний відсоток за користування кредитом - min 1%, max 31%.",
    },
    {
      id: 5,
      title: "Інформація про компанію:",
      text: "Наша адреса: ТОВ «Бистрокеш», 03035, м. Київ, вул. Митрополита Василя Липківського, 23",
    },
    {
      id: 6,
      title:
        "Інформація про повну вартість кредиту і приклад розрахунку (representative example of the total cost of the loan, including all applicable fees):",
      text: "Якщо ви взяли кредит на суму 1000 гривень на 90 днів з процентною ставкою 0,01% в день, то плата за користування грошима складе 0,01% в день, тобто 0,1 грн в день. Виходить ви повертаєте суму відсотків рівну 9 грн, тобто 0.1 грн * 90 днів. А також суму кредиту рівну 1 000 грн. Таким чином, загальна сума повернення складається з суми кредиту 1 000 і суми нарахованих відсотків за 90 дн. 9 грн і дорівнює 1090 грн. Мінімальний і максимальний період погашення боргу становить від 62 днів, до 365 днів. Максимальна річна відсоткова ставка, що включає позичковий відсоток, а також всі інші комісії і витрати за рік, або аналогічна ставка, розрахована відповідно до місцевого законодавства становить 31% річних.",
    },
    {
      id: 7,
      title: "Наслідки невчасного погашення кредиту",
      text: "Якщо ви плануєте брати кредит у будь-якій Організації, ви повинні дуже впевнено приймати рішенні. Ми гарантуємо конфіденційність послуг що ми надаємо. Та у нас на сайті ви можете прозоро побачити усі варіанти кредитної політики будь-якої з компанії. Та на підставі данних ви можете розраховувати ті умови які будуть для вас максимально вигідні та потрібі для вас саме зараз. Саме тому вам потрібно продумати доцільність вашого кредиту та подбати про своєчасні виплати по кредиту. Тому що при невчасному погашенні кредиту можуть бути не завжди приємні наслідки. Ми вас обов’язково попереджаємо про них. А ви будьте уважні та заздалегідь плануйте свій фінансові рішення.",
    },
  ];
  return (
    <div className="">
      <div className="px-2 lg:px-0">
        <table className="w-full text-sm" id="certificate">
          <tbody>
            {certificate.map((certif, key) => (
              <tr key={certif.id}>
                <td
                  className={`border-r border-gray100 px-3 py-2 ${
                    key ? "border-t border-gray100" : ""
                  }`}
                >
                  {certif.title}
                </td>
                <td
                  className={`px-3 py-2 ${
                    key ? "border-t border-gray100" : ""
                  }`}
                >
                  {certif.text}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className=" text-justify font-thin">
        * Проект здійснює підбір фінансових продуктів для клієнтів, будучи
        посередником між клієнтом, який бажає отримати фінансування, та
        кредитною установою, яка займається фінансуванням. FinCatalog не є
        фінансовою компанією та здійснює свою діяльність у рамках
        соціально-правового законодавства України. Робота цього сервісу
        пов'язана із здійсненням інвестиційної діяльності. Усі матеріали цього
        сайту охороняються законодавством про авторське право. Остаточні умови
        уточнюйте на веб-сайті кредитної організації. Сайт використовує cookie
        для надання послуг. Умови зберігання або доступу до cookie Ви можете
        настроїти у своєму браузері. Сервіс призначений для осіб віком від 18
        років.
      </p>
    </div>
  );
};

export default Certificate;
