const offers = [
  {
    id: 1,
    name: "LoviLave",
    image: "LoviLave.webp",
    link: "https://tds.pdl-profit.com/h/1cvf660e643245483",
    amount_to: 10000,
    days_to: 90,
    service_characteristics_link: "https://lovilave.com.ua/",
    warning_link:
      "https://docs.lovilave.com.ua/%D0%9F%D0%BE%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F.pdf",
    dop_info:
      "LoviLave - ТОВ ФК КІФ Адреса: 61072, м. Харків, вул. Отакара Яроша, буд. 24Б Телефон: +380 67 561 38 04 Ел-пошта: hello@lovilave.com.ua Max РРПС (APR): 1418017.60% Термін: від 1 доби до 90 днів Ліцензія: №2014 від 10.10.2019 Приклад розрахунку: 1000 грн на 30 днів, агальні витрати 1594 грн, APR 72959,02%",
  },
  {
    id: 2,
    name: "Amigo",
    image: "amigo.png",
    link: "https://tds.pdl-profit.com/h/1d8l660e630ce0aea",
    amount_to: 25000,
    days_to: 360,
    service_characteristics_link:
      "https://amigo.com.ua/s/perelik-rizvnovidiv-kreditiv",
    warning_link: "https://amigo.com.ua/s/perelik-rizvnovidiv-kreditiv",
    dop_info:
      "Miloan.ua - ТОВ «Мілоан» Адреса: 04107, м. Київ, вул. Багговутівська, 17-21 Телефон: +380 44 337 00 33 Ел-пошта: info@amigo.com.ua Max РРПС (APR): 36795.19% Термін: від 91 доби до 1 року Ліцензія: №16103409 від 14.07.2016 р Приклад розрахунку: 3000 грн на 3 місяці, комісія 916 грн, загальні витрати 3916 грн, APR 142.35%.",
  },
  {
    id: 3,
    name: "Finbar",
    image: "finbar.png",
    link: "https://tds.pdl-profit.com/h/1d3m660e6328a3f7e",
    amount_to: 20000,
    days_to: 120,
    service_characteristics_link: "https://starfin.com.ua/",
    warning_link:
      "https://starfin.com.ua/assets/files/%D0%9F%D0%BE%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%BF%D1%80%D0%BE%20%D0%BD%D0%B0%D1%81%D0%BB%D1%96%D0%B4%D0%BA%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D1%81%D0%BF%D0%BE%D0%B6%D0%B8%D0%B2%D1%87%D0%B8%D0%BC%20%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D0%BE%D0%BC.pdf",
    dop_info:
      "Avans.credit - ТОВ «Аванс Кредит» Адреса: 01015, м. Київ, вул. Лейпцизька, 15А Телефон: 073 020 10 40 Ел-пошта: info@finbar.com.ua Max РРПС (APR): 912,5 % (365 днів) Термін: від 90 до 120 днів Ліцензія: № 21/72-пк від 15.01.2021 Приклад розрахунку: сума – 1000 грн, строк – 3 місяці. Комісія - 750 грн (2,5% на день). Сума до повернення – 1750 грн, APR = 912,5%",
  },
  {
    id: 4,
    name: "Credos",
    image: "Credos.png",
    link: "https://tds.pdl-profit.com/h/1crq660e63c3e6e59",
    amount_to: 20000,
    days_to: 300,
    service_characteristics_link: "https://credos.com.ua/",
    warning_link: "https://credos.com.ua/static/docs/poperedzhennya.pdf",
    dop_info:
      "E-groshi.com - ТОВ «ФК Є ГРОШІ КОМ» Адреса: 01133, м. Київ, бул. Лесі Українки, буд. 26, оф. 407 Телефон: 0 800 20 02 21 Ел-пошта: info@creditkasa.ua Max РРПС (APR): 912,5% (365 днів) Термін: від 91 до 300 днів Ліцензія: № 2401 від 08.06.2017 Приклад розрахунку: 1000 грн на 3 міс – комісія 2250 грн, загальні витрати 3250 грн, APR 912,5%",
  },
  {
    id: 5,
    name: "Tengo",
    image: "Tengo.png",
    link: "https://tds.pdl-profit.com/h/1c86660e641d05986",
    amount_to: 25000,
    days_to: 360,
    service_characteristics_link:
      "https://tengo.ua/s/perelik-rizvnovidiv-kreditiv",
    warning_link: "https://tengo.ua/s/perelik-rizvnovidiv-kreditiv",
    dop_info:
      "Tengo.ua - ТОВ «Мілоан» Адреса: 04107, м. Київ, вул. Багговутівська, 17-21 Телефон: +38 044 337 00 37 Ел-пошта: info@tengo.ua Max РРПС (APR): 36795.19% Термін: від 91 доби до 1 року Ліцензія: №16103409 від 14.07.2016 Приклад розрахунку: 1000 грн на 360 днів, комісія 7263 грн, загальні витрати 8 263 грн, APR 803%.",
  },
  {
    id: 6,
    name: "Credit7",
    image: "Credit7.png",
    link: "https://tds.pdl-profit.com/h/1bnt660e63460cf1a",
    amount_to: 30000,
    days_to: 365,
    service_characteristics_link: "https://credit7.ua/kredytni-produkty/",
    warning_link: "https://credit7.ua/kredytni-produkty/",
    dop_info:
      "Credit7.ua - ТОВ «ЛІНЕУРА УКРАЇНА» Адреса: 02081, Київ вул. Дніпровська набережна 25 Телефон: 093 033 07 33 Ел-пошта: info@credit7.ua Max РРПС (APR): 119623.00% Термін: від 91 доби до 1 року Ліцензія: № 1177 від 19.03.2019 Приклад розрахунку: 5000 грн на 3 місяці, комісія 2675 грн, загальні витрати 3675 грн, APR 1070%",
  },
  {
    id: 7,
    name: "ШвидкоГроші",
    image: "sg.png",
    link: "https://tds.pdl-profit.com/h/1bv0660e638507860",
    amount_to: 30000,
    days_to: 140,
    service_characteristics_link:
      "https://sgroshi.com.ua/storage/app/public/docs/essential_characteristics/essential_characteristics.pdf",
    warning_link:
      "https://sgroshi.com.ua/ua/rozkritta-informacii?partner=salesdoubler&subpartner=063223&utm_source=salesdoubler&utm_medium=cps_st&utm_term=063223&offer=st&clickid=680055091",
    dop_info:
      "Sgroshi.com.ua - ТОВ «СПОЖИВЧИЙ ЦЕНТР» Адреса: 01032, м. Київ, вул.Саксаганського, 133А Телефон: 073 56 01 050 Ел-пошта: info@sgroshi.com Max РРПС (APR): 53956518,44% Термін: від 91 доби до 1 року Ліцензія: №438 від 28.02.2017 р. Приклад розрахунку: 1000 грн на 3 місяці, комісія 2250 грн, загальні витрати 3250 грн, APR 912.5%.",
  },
  {
    id: 8,
    name: "Finsfera",
    image: "Finsfera.jpeg",
    link: "https://tds.pdl-profit.com/h/1d6m660e6397b2db6",
    amount_to: 14000,
    days_to: 30,
    service_characteristics_link: "https://finsfera.ua/",
    warning_link:
      "https://finsfera.ua/media/downloads/Poperedzhennia-pro-mozhlyvi-naslidky-korystuvannia-kredytom.pdf",
    dop_info:
      "Finsfera - ТОВ  «ІННОВА ФІНАНС» Адреса: 01032, м. Київ, вул. Жилянська, буд. 68, прим. 280 Телефон: 0 800 31 01 11 Ел-пошта: support@finsfera.ua Max РРПС (APR): 241530,93% Термін: від 1 доби до 1 року Ліцензія: від 14.05.2021 № 21/1200-пк Приклад розрахунку: від 3,71 % (із розрахунку: сума кредиту 6701,00 грн., процентна ставка 0,01% на день, строк - 1 день) до 241530,93% (із розрахунку: сума кредиту 14000,00 грн., процента ставка 2,99% на день, строк - 30 днів)",
  },
  {
    id: 9,
    name: "Starfin",
    image: "Starfin.png",
    link: "https://tds.pdl-profit.com/h/1d5f660e63ae21494",
    amount_to: 20000,
    days_to: 100,
    service_characteristics_link: "https://starfin.com.ua/",
    warning_link:
      "https://starfin.com.ua/assets/files/%D0%9F%D0%BE%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%BF%D1%80%D0%BE%20%D0%BD%D0%B0%D1%81%D0%BB%D1%96%D0%B4%D0%BA%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D1%81%D0%BF%D0%BE%D0%B6%D0%B8%D0%B2%D1%87%D0%B8%D0%BC%20%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D0%BE%D0%BC.pdf",
    dop_info:
      "Starfin - ТОВ СТАР ФАЙНЕНС ГРУП Адреса: 03150, м. Київ, вул. Фізкультури, буд. 30В Телефон: 0 800 202 233 Ел-пошта: info@starfin.com.ua Max РРПС (APR): 674099,24% Термін: від 91 доби до 1 року Ліцензія: № 21/2224-пк від 13.08.2021 Приклад розрахунку: 1000 грн на 100 днів комісія 2500 грн, загальні витрати 3500 грн, APR 415563,24%",
  },

  {
    id: 10,
    name: "Money4you",
    image: "Money4you.png",
    link: "https://tds.pdl-profit.com/h/1bp1660e63d74ec06",
    amount_to: 25000,
    days_to: 80,
    service_characteristics_link:
      "https://money4you.ua/pro-nas/informatsiya-pro-produkt/",
    warning_link: "https://money4you.ua/pro-nas/informatsiya-pro-produkt/",
    dop_info:
      "Money4you.com.ua - TOB МАНІФОЮ Адреса: 03150, м. Київ, вул. Казимира Малевича, 86, літ. Д Телефон: 0(800)330024 Е-пошта: info@money4you.com.ua Max ГПС (APR) 1095% Термін: 62 дня - 1 рік 3000 ÷ на 3 місяці, комісія 8100 ₴, загальні витрати 11000 ₴, APR 1095%.",
  },
  {
    id: 11,
    name: "AvansCredit",
    image: "AvansCredit.png",
    link: "https://tds.pdl-profit.com/h/1cug660e63f47428e",
    amount_to: 20000,
    days_to: 120,
    service_characteristics_link: "https://avans.credit/",
    warning_link:
      "https://avans.credit/assets/files/%D0%9F%D0%BE%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%BF%D1%80%D0%BE%20%D0%BD%D0%B0%D1%81%D0%BB%D1%96%D0%B4%D0%BA%D0%B8%20%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D1%81%D0%BF%D0%BE%D0%B6%D0%B8%D0%B2%D1%87%D0%B8%D0%BC%20%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D0%BE%D0%BC.pdf",
    dop_info:
      "Avans.credit - ТОВ «Аванс Кредит» Адреса: 01015, м. Київ, вул. Лейпцизька, 15А Телефон: +38 093 020 25 20 Ел-пошта: info@avans.credit Max РРПС (APR): 660 520,14% Термін: від 91 доби до 1 року Ліцензія: №B0000448 від 02.12.2021 Приклад розрахунку: 5000 грн на 3 місяці, комісія 5869 грн, загальні витрати 10869 грн, APR 620.5%.",
  },
  {
    id: 12,
    name: "E-groshi",
    image: "E-groshi.png",
    link: "https://tds.pdl-profit.com/h/1bss660e6409328b6",
    amount_to: 50000,
    days_to: 360,
    service_characteristics_link: "https://e-groshi.com/poslugy/",
    warning_link: "https://e-groshi.com/poslugy/",
    dop_info:
      "E-groshi.com - ТОВ «ФК Є ГРОШІ КОМ» Адреса: 49044, м. Дніпро, вул. Шевченка 51Д Телефон: 0800-300-330 Ел-пошта: info@e-groshi.com Max РРПС (APR): 162544.52% Термін: від 91 доби до 1 року Ліцензія: №В0000367 Приклад розрахунку: 1000 грн на 3 місяці, відсотки 1830 грн, загальні витрати 2830 грн, APR 786%.",
  },


];

export default offers;
